import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import config from '../../config.json';
import Layout from '../components/layout/layout';
// import BlogPosts from '../component/Posts/BlogPosts';
import StickyBox from '../components/sticky-box/sticky-box';
import '../styles/blog-page.sass';
import { graphql, Link } from 'gatsby';
import CalendarSvg from '../assets/um-calendar-article.svg';
import ClockSvg from '../assets/um-clock-article.svg';
import Seo from '../components/seo/seo';
import Button from '../components/button/button';
import ArrowSvg from '../assets/um-arrow-pagination.svg';
import CompareBox from '../domain/compare-box/compare-box';
import Avatar from '../assets/avatar.png';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import FacebookSvg from '../assets/um-facebook-icon.svg';
import LinkedInSvg from '../assets/um-linkedin-icon.svg';
import TwitterSvg from '../assets/um-twitter-icon.svg';
import LastPosts from '../domain/last-posts/last-posts';
import {ReferrerCookie} from "../components/referrer/referrer";

type Props = {
  pageContext: any;
  data: any;
  readingTime: any;
};

const Post = ({ pageContext, data }: Props) => {
  const post = pageContext.data;
  const readingTime = pageContext.readingTime;

  const pageQueryData = data;
  const image = getImage(
    pageQueryData.wpPost.featuredImage.node.localFile
  ) as IGatsbyImageData;
  const imageMeta = image?.images?.fallback?.src || undefined;
  const type = pageContext.type;

  const next = pageContext.next;
  const previous = pageContext.previous;

  const articleStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: post.seo.title,
    description: post.seo.metaDesc,
    image: `${config.SITE_URL}/${imageMeta}`,
    datePublished: new Date(post.dateGmt),
    dateModified: new Date(post.modifiedGmt),
    author: {
      '@type': 'Person',
      name: post.author.node.name,
    },
    publisher: {
      '@type': 'Organization',
      name: 'LeadGuru Sp. z o.o.',
    },
  };

  return (
    <Layout>
      <Seo
        title={post.seo.title}
        description={post.seo.metaDesc}
        keywords={post.seo.focuskw}
        article={true}
        pathname={`/${type}/${post.slug}/`}
        image={imageMeta}
        jsonLd={JSON.stringify(articleStructuredData)}
      />
      <div className="bg-blueLight">
        <div className="container mx-auto px-4 py-6 lg:py-10">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="w-full">
              <GatsbyImage image={image} alt="" className="rounded-md" />
            </div>
            <div className="flex items-center justify-center p-4 lg:justify-start lg:p-8">
              <div className="">
                <h1 className="mb-4 text-center text-2xl font-semibold lg:text-left lg:text-4xl lg:leading-[3rem]">
                  {post.title}
                </h1>
                <a href={config.CALC_URL + ReferrerCookie()} target="_blank">
                  <Button
                    visualType="primary"
                    className="w-full text-white lg:w-fit"
                  >
                    Porównaj ubezpieczenia
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto px-4 py-4 lg:py-8">
          <div className="grid grid-cols-1 gap-24 lg:grid-cols-6">
            <div className="lg:col-span-4">
              <div className="hidden font-secondary text-lg lg:block">
                <Link to="/" className="hover:text-primary">
                  Strona główna
                </Link>
                <img src={ArrowSvg} alt="" className="inline pl-2" />
                <Link to="/blog/" className="pl-2 hover:text-primary">
                  Blog
                </Link>
                <img src={ArrowSvg} alt="" className="inline pl-2" />
                <span className="pl-2 text-primary">{post.title}</span>
              </div>
              <div className="my-4 flex flex-col lg:my-8 lg:flex-row lg:justify-between">
                <div className="flex items-center lg:items-start">
                  <img
                    src={post?.author?.node?.avatar?.url || Avatar}
                    title=""
                    className="h-12 w-12 rounded-full"
                    alt=""
                  />
                  <div className="flex flex-col pl-2">
                    <p className="pb-0 font-secondary text-lg">
                      {post.author.node.name}
                    </p>
                    <p className="font-secondary text-[#898E98]">
                      {post.author.node.seo.title}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex lg:mt-0">
                  <div className="flex items-center lg:mt-0">
                    <img src={ClockSvg} alt="" />
                    <span className="pl-2 font-secondary">
                      {Math.floor(readingTime.minutes)} min.
                    </span>
                  </div>
                  <div className="flex items-center pl-4 lg:mt-0">
                    <img src={CalendarSvg} alt="" />
                    <time className="pl-2 font-secondary">{post.dateGmt}</time>
                  </div>
                </div>
              </div>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: post.content }}
              ></div>

              <div className="mt-8 flex lg:mt-10 lg:items-start">
                <img
                  src={post?.author?.node?.avatar?.url || Avatar}
                  title=""
                  className="h-20 w-20 rounded-full"
                  alt=""
                />
                <div className="p-4">
                  <div className="flex flex-col">
                    <p className="pb-0 font-secondary text-lg">
                      {post.author.node.name}
                    </p>
                    <p className="font-secondary text-[#898E98]">
                      {post.author.node.seo.title}
                    </p>
                  </div>
                  <p className="mt-4 font-secondary">
                    {post.author.node.seo.metaDesc}
                  </p>
                </div>
              </div>
              <div className="mt-6 flex w-full justify-between">
                <div className="">
                  {previous?.slug && (
                    <Link
                      to={`/${type}/${previous?.slug}/`}
                      className="flex items-center hover:text-primary"
                    >
                      <img src={ArrowSvg} className="rotate-180" alt="" />
                      <span className="pl-2">Poprzedni artykuł</span>
                    </Link>
                  )}
                </div>
                <div className="">
                  {next?.slug && (
                    <Link
                      to={`/${type}/${next?.slug}/`}
                      className="flex items-center self-end hover:text-primary"
                    >
                      <span className="pr-2">Następny artykuł</span>
                      <img src={ArrowSvg} alt="" />
                    </Link>
                  )}
                </div>
              </div>
              <div className="mt-7 mb-7">
                <p className="text-center text-lg uppercase text-primary lg:text-left">
                  Udostępnij ten artykuł:
                </p>
                <div className="mt-4 flex justify-center lg:justify-start">
                  <FacebookShareButton
                    url={`${config.SITE_URL}/${type}/${post.slug}/`}
                    className="mr-4 flex items-center font-secondary text-xl"
                  >
                    <img src={FacebookSvg} alt="" className="mr-2 h-7 w-7 " />
                    <span className="hidden lg:block"> Facebook</span>
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={`${config.SITE_URL}/${type}/${post.slug}/`}
                    className="mr-4 flex items-center font-secondary text-xl"
                  >
                    <img src={LinkedInSvg} alt="" className="mr-2 h-7 w-7" />
                    <span className="hidden lg:block">LinkedIn</span>
                  </LinkedinShareButton>
                  <TwitterShareButton
                    url={`${config.SITE_URL}/${type}/${post.slug}/`}
                    className="flex items-center font-secondary text-xl"
                  >
                    <img src={TwitterSvg} alt="" className="mr-2 h-7 w-7" />
                    <span className="hidden lg:block">Twitter</span>
                  </TwitterShareButton>
                </div>
              </div>
            </div>
            <div className="lg:col-span-2">
              <StickyBox>
                <CompareBox referrer={ReferrerCookie()}/>
              </StickyBox>
            </div>
          </div>
        </div>
        <LastPosts />
      </div>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query BlogPost($id: String) {
    wpPost(id: { eq: $id }) {
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 750, height: 360, quality: 90)
            }
          }
        }
      }
    }
  }
`;
